.container {
  padding: 1em 2em;
}

.tableForm {
  padding-top: 2em;
  padding-bottom: 2em;
}
.greendot {
  height: 5px;
  width: 5px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 2px;
  margin-right: 12px;
}
/* .pdfbody {
  height: 80vh;
} */
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.closeicon {
  width: 25px;
  padding: 5px;
}
.pdfclose {
  float: right;
  cursor: pointer;
  margin-top: -11px;
  font-size: 20px;
  font-weight: 700;
  color: #f07167;
  padding: 2px;
}
.yellowdot {
  height: 5px;
  width: 5px;
  background-color: #ffa743;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 2px;
  margin-right: 12px;
}
.reddot {
  height: 5px;
  width: 5px;
  background-color: #f50057;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 2px;
  margin-right: 12px;
}
.modalBox {
  position: fixed; /* Stay in place */
  z-index: 1111111 !important; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 45%; /* Could be more or less, depending on screen size */
  border-radius: 20px;

  position: relative;
}

.closeIcn {
  position: absolute;
  right: 5px;
  width: 24px;
  cursor: pointer;
  margin-top: -15px;
}

.btnAdjHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 2em 0; */
}
.btnAdjHeader > button:nth-child(1) {
  margin-right: 1em;
  /* margin: 2em 0; */
}

.subBtn10 {
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  width: 20%;
  color: white;
  border-radius: 10px;
  margin-top: 24px;
  font-size: 15px;
  cursor: pointer;
}

.logoheader {
  display: flex;
}

.modal-content > div:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoHeader {
  width: 229px;
  float: left;
  margin-left: 10px;
  margin-top: -1px;
}

.logoutImg {
  margin-right: 19px;
  margin-left: 20px;
  margin-top: -8px;
  cursor: pointer;
}

.floatRighter {
  display: flex;
}

.floatRighter span {
  color: #f07167;
  font-size: 18px;
}

.facilityName {
  color: #00afb9;
  font-size: 22px;
}
.ReactModal__Overlay {
  z-index: 11111 !important;
}
.linkBack {
  color: #f07167;
  cursor: pointer;
  margin-top: 20px;
  margin-right:40px;
  
}
.SearchHeader{
  padding-left: 30px;
  display: flex;
  align-items: center;
  width:80vw;
}
.SearchHeaderlabel{
  font-size: 20px;
  font-weight: 400;
}
.SearchParam{
  margin: 3px 10px 0 10px;
  font-size: 15px;
  display:flex;
}
.SearchParams{
  display: flex;
  flex:1 1 80%;
}
.SearchParamValue{
  color: #00afb9;
  padding-right: 5px;
}
.searchWrapper{
    width: 360px !important;
    margin-top: 10px !important;
    display: flex !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid #ccc !important;
    border-left: 0 !important;
    border-radius: 0 !important;
    height: 33px !important
}
.icon_down_dir{
  top:60% !important;
  width:11px !important;
}
.optionListContainer{
  z-index: 4 !important;
  top:0;
}
.highlightOption{
  background:none ;
  color:black;
}
.multiSelectContainer li{
  text-transform: capitalize;
}
.multiSelectContainer li:hover{
  background: #eee;
  color:black;
}
.chip{
  background-color: #00AFB9;
  margin-bottom:0 !important;
  text-transform: capitalize;
}
.linkfilter {
  margin-right: 40px;
  color: #00afb9;
  cursor: pointer;
  margin-top: 40px;
}

.sortingcolor {
  color: blue;
}

.filter_row {
  /* display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-bottom: 10px; */

  width: 300px;
}
.search_label {
  font-size: 16px !important;
  margin: 12px 32px;
}
.search_filter {
  width: 250px;
}

.searchRow {
  display: flex;
  justify-content: center;

  margin-bottom: 20px;
}

.srchbtn {
  background: #f07167;
  border: none;
  width: 100px;
  padding: 10px;
  font-size: 15px;
  color: white;
  border-radius: 9px;
  cursor: pointer;
}
.srchbtn:focus {
  /* border: 1px solid #dd625a !important; */
  border: #a84f48 solid 1px !important;
}

.status_filter {
  width: 230px;
}

.TestTypegrid1,
.TestResultgrid1 {
  width: 100%;
  margin: 0 20px !important;
}

/* @media only screen and (max-width: 950px) {
  .TestTypegrid1,
  .TestResultgrid1 {
    width: 100vw;
  }
} */
.SearchLabel {
  line-height: 0 !important;
}

.MuiTableSortLabel-root {
  display: flex !important;
  flex-direction: column-reverse !important;
  text-align: center;
  /* width: 130px !important; */
}

/* .MuiTableCell-head {
  line-height: 0 !important;
} */


@media only screen and (max-width: 960px) {
  .searchWrapper {
    width:98% !important;
    margin-left: 20px;
  }
  
}

.MuiTablePagination-actions{
  display: none;
}

/* .MuiFormControl-root{
   float: right;
  margin-top:-100px; 
   width: 100px;
} */
.MuiTablePagination-root{
  margin-right: 30px;
  color:black;
}

.pageNumber-label{
  color: black;
  font-size: 16px;
  font-weight: 600;
}



.pageNumberClass{
  float: right;
  display: flex;
  flex-direction: row;
}

.label-pageNumber{
  color:#333 !important;
}

.selectPage-class{
  width: 130px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-around;
}

.selectPage-label{
  font-size: 14px;
  width:85%;
  font-weight: 400 !important;

}

.selectPage-dropdown{
  font-size: 14px;
  font-weight: 400 !important;
  /* width:10%; */
}

/* .label-pageSelect{
  color:#333 !important;
} */